"use client";
import { usePathname } from "next/navigation";
import React, { useEffect } from "react";

type Props = {};

function StickyHeader({}: Props) {
  const pathname = usePathname();

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        for (const entry of entries) {
          const header = document.querySelector("#header");
          header?.classList.toggle("header--sticky", !entry.isIntersecting);
        }
      },
      { threshold: 1 },
    );

    const target = document.querySelector(".hero");

    if (target) observer.observe(target);

    return () => observer.disconnect();
  }, [pathname]);

  useEffect(() => {
    document.querySelector("#header")?.classList.remove("header--sticky");
  }, [pathname]);

  return null;
}

export default StickyHeader;
