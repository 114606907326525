"use client";
import { Half2Icon, SunIcon } from "@radix-ui/react-icons";
import React, { useId } from "react";

type Props = {};

function ColorModeToggle({}: Props) {
  const id = useId();
  return (
    <div className="colormode-toggle">
      <SunIcon />
      <input
        id={id}
        type="checkbox"
        onChange={(e) => {
          document
            .querySelector("html")
            ?.classList.toggle("high-contrast", e.currentTarget.checked);
        }}
      />
      <label htmlFor={id} className="colormode-toggle__slider">
        <span className="sr-only">Hoher Kontrast Modus togglen</span>
      </label>
      <Half2Icon />
    </div>
  );
}

export default ColorModeToggle;
