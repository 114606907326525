"use client";
import React from "react";

import { signOut } from "next-auth/react";
type Props = {};

function SignOutButton({}: Props) {
  return (
    <button
      className="signout-button"
      onClick={() => signOut({ redirect: true, callbackUrl: "/login" })}
    >
      Logout
    </button>
  );
}

export default SignOutButton;
