"use client";
import React, { useEffect, useRef } from "react";
import { usePathname } from "next/navigation";

declare global {
  interface Window {
    _paq?: {
      push: (args: string[]) => void;
    };
  }
}

type Props = {};

function MatomoPageView({}: Props) {
  const initialRef = useRef(true);
  const pathname = usePathname();

  useEffect(() => {
    if (initialRef.current) {
      initialRef.current = false;
      return;
    }
    window?._paq?.push(["setCustomUrl", pathname]);
    window?._paq?.push(["setDocumentTitle", document.title]);
    window?._paq?.push(["trackPageView"]);
  }, [pathname]);

  return null;
}

export default MatomoPageView;
