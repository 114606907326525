"use client";

import React, { useEffect, useRef, useState } from "react";
import * as NavigationMenu from "@radix-ui/react-navigation-menu";
import Link from "next/link";
import { removeBaseUrl } from "@utils/url";
import { CaretDownIcon } from "@radix-ui/react-icons";
import { useSession } from "next-auth/react";
import Hamburger from "@components/Hamburger";
import { usePathname } from "next/navigation";

type Props = {
  items: NavigationItem[];
  className?: string;
  ariaLabel: string;
  id: string;
};

function Menu(props: Props) {
  const { items, className, ariaLabel, id } = props;
  const hamburgerRef = useRef<HTMLButtonElement | null>(null);
  const pathname = usePathname();
  let forceMount: true | undefined;

  useEffect(() => {
    if (hamburgerRef.current) hamburgerRef.current.ariaExpanded = "false";
  }, [pathname]);

  return (
    <>
      <Hamburger
        onClick={(e) => {
          e.currentTarget.ariaExpanded =
            e.currentTarget.ariaExpanded === "true" ? "false" : "true";
        }}
        aria-label="Navigation umschalten"
        aria-controls={id}
        aria-expanded="false"
        ref={hamburgerRef}
      />
      <NavigationMenu.Root
        orientation="horizontal"
        className={className}
        aria-label={ariaLabel}
        id={id}
      >
        <NavigationMenu.List className="menu__list">
          {items
            ?.filter((item) => removeBaseUrl(item["@id"]) !== "/")
            .map((item) => (
              <NavigationMenu.Item key={item["@id"]}>
                {item?.items?.length ? (
                  <>
                    <NavigationMenu.Trigger className="menu__trigger">
                      {item.title}{" "}
                      <CaretDownIcon
                        width={20}
                        height={20}
                        aria-hidden
                        className="menu__caret-down"
                      />
                    </NavigationMenu.Trigger>
                    <NavigationMenu.Content className="menu__content">
                      <NavigationMenu.Sub className="menu__sub">
                        <NavigationMenu.List className="menu__sub-list">
                          <NavigationMenu.Item className="menu__item menu__sub-item">
                            <NavigationMenu.Link
                              asChild
                              href={removeBaseUrl(item["@id"])}
                            >
                              <Link
                                href={removeBaseUrl(item["@id"])}
                                className="menu__sub-link"
                              >
                                Übersicht {item.title}
                              </Link>
                            </NavigationMenu.Link>
                          </NavigationMenu.Item>
                          {item.items.map((subItem) => (
                            <NavigationMenu.Item
                              key={subItem["@id"]}
                              className="menu__item menu__sub-item"
                            >
                              <NavigationMenu.Link
                                className="menu__sub-link"
                                asChild
                              >
                                <Link href={removeBaseUrl(subItem["@id"])}>
                                  {subItem.title}
                                </Link>
                              </NavigationMenu.Link>
                            </NavigationMenu.Item>
                          ))}
                        </NavigationMenu.List>
                      </NavigationMenu.Sub>
                    </NavigationMenu.Content>
                  </>
                ) : (
                  <NavigationMenu.Link className="menu__link" asChild>
                    <Link href={removeBaseUrl(item["@id"])}>{item.title}</Link>
                  </NavigationMenu.Link>
                )}
              </NavigationMenu.Item>
            ))}
        </NavigationMenu.List>
        <NavigationMenu.List className="menu__list-secondary">
          <NavigationMenu.Item>
            <NavigationMenu.Link className="menu__link" asChild>
              <Link href="/login">Login interner Bereich</Link>
            </NavigationMenu.Link>
          </NavigationMenu.Item>
          <NavigationMenu.Item>
            <NavigationMenu.Link className="menu__link" asChild>
              <Link href="/impressum">Impressum</Link>
            </NavigationMenu.Link>
          </NavigationMenu.Item>
          <NavigationMenu.Item>
            <NavigationMenu.Link className="menu__link" asChild>
              <Link href="/suche">Suche</Link>
            </NavigationMenu.Link>
          </NavigationMenu.Item>
          <NavigationMenu.Item>
            <NavigationMenu.Link className="menu__link" asChild>
              <Link href="/datenschutz">Datenschutz</Link>
            </NavigationMenu.Link>
          </NavigationMenu.Item>
        </NavigationMenu.List>
      </NavigationMenu.Root>
    </>
  );
}

export default Menu;
