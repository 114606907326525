"use client";
import React from "react";

type Props = {};

function VinegarButton({}: Props) {
  return (
    // @ts-expect-error - vinegar exists :)
    <button onClick={() => window?.vinegar?.show()} className="footer__link">
      Cookies
    </button>
  );
}

export default VinegarButton;
